
import Turma from "@/interfaces/Turma";
import router from "@/router";
import BaseService from "@/services/admin/BaseService";
import store from "@/store";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const $q = useQuasar();
    const route = useRoute();
    const turmas = ref<Turma[]>();
    const redacaoId = route.params.id;
    const goTo = (rota: string): void => {
      router.push({ name: rota });
    };

    const getTurmas = (): void => {
      BaseService.list(`redacoes/find-turmas-by-redacao/${route.params.id}`)
        .then((res) => {
          turmas.value = res.data;
        })
        .catch((err) => {
          console.log(err);
          showNotify({
            type: "negative",
            message: "Erro ao buscar turmas",
          });
        });
    };

    onMounted(() => {
      getTurmas();
    });

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return { turmas, goTo, store, redacaoId };
  },
});
